<template>
  <div>
    <div class="container-fluid bg-content">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <div class="content-button-flex-end">
            <v-btn
              class="button-yellow mon-bold"
              elevation="0"
            >
              {{ texts.warehouse.attachments.buttonAddDocument }}
            </v-btn>
          </div>
          <div class="content-card mt-5 mb-5">
            <div class="display-flex align-items-center">
              <p class="text-title-table mon-bold pl-9 mt-3 mb-3">{{ texts.warehouse.attachments.textName }}</p>
              <v-spacer></v-spacer>
              <p class="text-title-table mon-bold pr-9 mt-3 mb-3">{{ texts.warehouse.attachments.textActions }}</p>
            </div>
            <div class="separator-line ml-2 mr-2"></div>
            <div class="pa-9 display-flex align-items-center justify-content-center">
              <p class="text-documents mon-regular">
                {{ texts.warehouse.attachments.textNoDocuments }}
              </p>
            </div>
          </div>
          <div class="content-button-flex-end">

            <v-btn
              class="button-transparent mon-regular"
              elevation="0"
            >
              {{ texts.warehouse.attachments.buttonClose }}
            </v-btn>
            <v-btn
              class="button-yellow mon-bold ml-5"
              elevation="0"
            >
              {{ texts.warehouse.attachments.buttonAddWarehouse }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddWarehouseTabContent2Layout",
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      //VARIABLES
    };
  },
  beforeMount() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.text-documents {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title-table {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.separator-line {
  border: 1px solid #cecece;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
}

.content-button-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-transparent {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}
</style>